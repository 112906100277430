// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://google.com',
  freeUI: 'https://google.com',
  figma: 'https://google.com',
  components: 'https://google.com',
  zoneUI: 'https://google.com',
  auth: '/auth',
  root: '/',
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  docs: 'https://docs.minimals.cc',
  "@app/*": ["./app/*"],
  "@assets/*": ["./assets/*"],
  "@components/*": ["./components/*"],
  "@styles/*": ["./styles/*"],
  "@utils/*": ["./utils/*"],
  //Storefront Stalker Pro Paths
  
  // AUTH
  // auth: {
  //   amplify: {
  //     login: `${ROOTS.AUTH}/amplify/login`,
  //     verify: `${ROOTS.AUTH}/amplify/verify`,
  //     register: `${ROOTS.AUTH}/amplify/register`,
  //     newPassword: `${ROOTS.AUTH}/amplify/new-password`,
  //     forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
  //   },
  //   jwt: {
  //     login: `${ROOTS.AUTH}/jwt/login`,
  //     register: `${ROOTS.AUTH}/jwt/register`,
  //   },
  //   firebase: {
  //     login: `${ROOTS.AUTH}/firebase/login`,
  //     verify: `${ROOTS.AUTH}/firebase/verify`,
  //     register: `${ROOTS.AUTH}/firebase/register`,
  //     forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
  //   },
  //   auth0: {
  //     login: `${ROOTS.AUTH}/auth0/login`,
  //   },
  // },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    chat: `https://discord.com/channels/791399411481182289/793690338161590333`,
    storefronts: {
      root: `${ROOTS.DASHBOARD}/storefronts`,
      add: `${ROOTS.DASHBOARD}/storefronts/add`,
    },    
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      storefronts: `${ROOTS.DASHBOARD}/storefronts`,
      topstores: `${ROOTS.DASHBOARD}/explore-storefronts`,
      asins: `${ROOTS.DASHBOARD}/asins`
    },
    user: {
      account: `https://dash.whop.com/`,
      affiliate: 'https://whop.com/affiliates/customer/my-links/'
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
  },
};
